.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

body{
  background-color: #eee !important;
}

.card-header{
  background-color: #ababab !important;
}

.card{
  border-color: #ababab !important;
}

.bg-table-head-primary{  
  color: #fff !important;
}

.bg-table-head-primary > tr th {
  background-color: #283d62 !important;
}

.lineThrough {
  text-decoration: line-through;
}

.list-group-item:hover{
  cursor: pointer !important;
  background-color: #eee !important;
  font-weight: bold !important;
}

.table-th-style{
  font-size: 12px !important;
}

.table-td-style{
  font-size: 11px !important;
}

.table-ft-style{
  font-size: 14px !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
